
import { defineComponent, ref, computed, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SplitPaymentService from "@/core/services/car/SplitPaymentService";
import MasterService from "@/core/services/car/MasterService";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import splitPaymentList from "@/models/car/ISplitPaymentList";
import { customFunction, roles } from "@/store/stateless/store";
import mainSocket from "./../../../main-socket";
import { Mutations } from "@/store/enums/StoreEnums";
import SplitPaymentModal from "./SplitPaymentModal.vue";
import moment from "moment";
import router from '@/router';

interface SplitPaymentBodyParam {
per_page: string;
agent: string;
payment_type: any;
keyword: string;
start_date: string;
end_date: string;
installment_payment_status: string;
reload: boolean;
}

export default defineComponent({
name: "split-payment-list",
components: {
  SplitPaymentModal
},
data() {
  return {
    shortcuts: [
      {
        text: "Last week",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        })()
      },
      {
        text: "Last month",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        })()
      },
      {
        text: "Last 3 months",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        })()
      }
    ],
    value1: "",
    installmentStatus: [
      { id: 2, name: "No" },
      { id: 1, name: "Yes" },
    ]
  };
},
setup() {
  let responseResult = ref<any>({});
  responseResult.value.success = false;
  responseResult.value.failed = false;
  responseResult.value.execute = false;
  const store = useStore();
  const route = useRoute();
  let currentInvoiceId = ref<any>();
  let splitPaymentList = ref<splitPaymentList>();
  let paymentTypes = ref<any>();
  let userObj: any = {};
  let userStr: any = {};
  let bodyParameters = {} as SplitPaymentBodyParam;
  const splitPaymentModalRef = ref();
  const startOfYear    = moment().startOf('year').format('YYYY-MM-DD');
  const endOfNextMonth = moment().add(1,'month').endOf('month').format('YYYY-MM-DD');
  const startOfMonth   = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth     = moment().endOf('month').format('YYYY-MM-DD');

  bodyParameters = {
    per_page: "50",
    agent: "mine",
    payment_type: -1,
    keyword: "",
    start_date: startOfYear,
    end_date: endOfNextMonth,
    installment_payment_status: "2",
    reload:true
  };

  const openSplitPaymentModal = computed(() => {
    return store.getters.getSplitPaymentModal;
  });

  const isError = ref<boolean>(false);

  const closeSplitPaymentModal = (e) => {
    e.item.installment_status_id = 2;
    isError.value = false;
    store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL, false);
    store.commit(Mutations.SET_SPLIT_PAYMENT_UPDATE_ERROR, false);
  };

  const submitSplitPaymentModal = (e) => {
    store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_LOADING);
    Promise.all([SplitPaymentService.updateSplitPaymentStatus(e)])
      .then((data) => {
        store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_LOADING);
        store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL, false);
        
        if(data[0].data.success !== undefined && !data[0].data.success){

          store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL, true);
          store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_VALUE, {
                                                                  installment_id: '',
                                                                  status: '',
                                                                  payment_date: '',
                                                                  cib_receipt: '',
                                                              });
        } else {
          store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_LOADING,false);
          getSplitPaymentList(bodyParameters,false);
        }
        splitPaymentModalRef.value.first();
      })
      .catch((err) => {
        isError.value = true;
        store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_LOADING);
      });
  };



  const changeSplitPaymentStatus = (item) => {
    if(item.installment_status_id == 1){
      store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL, true);
      store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_VALUE, {
          item,
          installment_id: item.installment_id,
          status: item.installment_status_id,
          cib_receipt_no: item.cib_receipt_no,
          installment_amount: item.installment_amount,
      });
      splitPaymentModalRef.value.toFirst()
    }else{
      store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL, false);
      store.commit(Mutations.SET_SPLIT_PAYMENT_MODAL_VALUE, false);
    }

  };


  userStr = LocalStorageServices.getUser();
  userObj = JSON.parse(userStr);
  const permissions = computed(() => {
    return store.state.AuthModule.aclPermission;
  });
   
  const quoteRefFromUrl = ((typeof route?.params?.quoteRef === 'string')) ? route?.params?.quoteRef : '';


  onMounted(() => {
    if(!roles.approvedInvoice.includes(roles.userId())) window.location.href = '/#/404'
    
    
    MenuComponent.reinitialization();
    setCurrentPageBreadcrumbs("Split Payments", ["Approved Invoices"]);
    bodyParameters.keyword = "";
    bodyParameters.agent = [1, 9].includes(userObj.role_id) ? "all" : "mine";
    getPaymentTypeList()


    mainSocket
      .channel("policy-channel")
      .listen("CarPolicyCountSocketEvent", (response) => {
        if (response.policy_status) {
          getSplitPaymentList(bodyParameters, false);
        }
      });
  });
  
  const getPaymentTypeList = () => {
    store.commit(Mutations.SET_AI_LOADING, true);
    Promise.all([
      MasterService.getSplitPaymentTypes(),
      getSplitPaymentList(bodyParameters, false, true)
    ]).then((data) => {
          const allData = {
          label: "All Transaction Types",
          value: "all"
        };
        selectedPaymentType.value = "all";
        let result = store.getters.getPaymentTypeList;
        result.unshift(allData);
        paymentTypes.value = result;
        selectedInstallmentPaymentStatus.value = (quoteRefFromUrl.length > 0) ? "3" : "2"
        store.commit(Mutations.SET_AI_LOADING, false);
      }).catch((error)=>{
        store.commit(Mutations.SET_AI_LOADING, false);
      });
  }

  let loadSplitPaymentInformation = computed(() => {
    return store.getters.getSplitPaymentListingDom;
  });

  const getPaginationUrl = (index) => {
    bodyParameters.keyword = search.value;
    Promise.all([
      SplitPaymentService.getSplitPaymentList(bodyParameters, currentPage.value)
    ]).then((data) => {
      splitPaymentList.value = store.getters.getSplitPaymentsList;
    });
  };
  const onPerPageChange = (event) => {
    bodyParameters.per_page = event.target.value;
    getSplitPaymentList(bodyParameters);
  };

  const getSplitPaymentListFor = (listFor) => {
    bodyParameters.agent = listFor;
    bodyParameters.keyword = "";
    getSplitPaymentList(bodyParameters);
  };

  const getPaymentTypeFor = (listFor) => {
    if (listFor === "all" || listFor === "mine") {
      bodyParameters.agent = listFor;
      bodyParameters.payment_type = -1;
    } else {
      bodyParameters.payment_type = listFor;
    }
    getSplitPaymentList(bodyParameters);
  };


  const getInstallmentPaymentStatus = (value) => {
    bodyParameters.installment_payment_status = value;
    selectedInstallmentPaymentStatus.value = value;
    if(quoteRefFromUrl.length == 0 && filterDate.value){
      if(value == 1){
        bodyParameters.start_date = startOfMonth;
        bodyParameters.end_date   = endOfMonth;
        filterDate.value = [
            startOfMonth,
            endOfMonth
        ]

      }else{
        bodyParameters.start_date = startOfYear;
        bodyParameters.end_date   = endOfNextMonth;
        filterDate.value = [
          startOfYear,
          endOfNextMonth
        ]
      }
    }
    getSplitPaymentList(bodyParameters);
  };

  const filterDate = ref([
      startOfYear,
      endOfNextMonth
  ]);

  const getSplitPaymentListPeriod = (listPeriod) => {
    if(listPeriod) {
      var startTS = moment(listPeriod[0]);
      var endTS = moment(listPeriod[1]);
      bodyParameters.start_date = startTS.format("YYYY-MM-DD");
      bodyParameters.end_date = endTS.format("YYYY-MM-DD");
    } else {
      bodyParameters.start_date = '';
      bodyParameters.end_date = '';
    }

    search.value         = (quoteRefFromUrl.length > 0) ? quoteRefFromUrl : "";
    bodyParameters.keyword = search.value;
    
    getSplitPaymentList(bodyParameters);
  };

  const search = ref<string>("");

  const searchSplitPayment = () => {
    bodyParameters.keyword = search.value;
    getSplitPaymentList(bodyParameters);
  };

  const selectedPaymentType = ref("");
  const selectedInstallmentPaymentStatus = ref("2");

  const resetBodyParam = () => {
  
    if(quoteRefFromUrl && quoteRefFromUrl.length > 0){
      /**
       * Removing the quote ref from the url
       */
        router.push({ name: 'split-payments', params: { quoteRef:'' } })
              .then(() => router.go(0))
    }

    filterDate.value = [
    startOfYear,
    endOfNextMonth
      ]
    search.value = "";
    bodyParameters.per_page = "50";
    bodyParameters.agent = userObj.role_id == 2 ? "mine" : "all";
    bodyParameters.payment_type = -1;
    bodyParameters.keyword = "";
    bodyParameters.start_date = startOfYear;
    bodyParameters.end_date = endOfNextMonth;
    bodyParameters.installment_payment_status = "2";
    if (
      permissions.value.is_sales_agent ||
      permissions.value.is_team_leader
    ) {
      selectedPaymentType.value = "mine";
    }
    if (
      permissions.value.is_admin ||
      permissions.value.is_accountant ||
      permissions.value.is_manager
    ) {
      selectedPaymentType.value = "all";
    }
    selectedInstallmentPaymentStatus.value = "2"
    getSplitPaymentList(bodyParameters);
  };

  const getSplitPaymentList = (bodyParameters, reload = true,isInitalCall=false) => {
    currentPage.value = 1;
    bodyParameters.reload = reload
    if(isInitalCall && quoteRefFromUrl.length > 0){
      bodyParameters.keyword    = quoteRefFromUrl;
      search.value              = quoteRefFromUrl;
      bodyParameters.installment_status_id      = -1;
      bodyParameters.installment_payment_status = "3";
      selectedInstallmentPaymentStatus.value    = "3";
      bodyParameters.start_date = '';
      bodyParameters.end_date = '';
      filterDate.value = []
    }
    
    Promise.all([
      SplitPaymentService.getSplitPaymentList(bodyParameters)
    ]).then((data) => {
      splitPaymentList.value = store.getters.getSplitPaymentsList;
    });
  };

  const userPermissions = () => {
    return store.getters.getUserPermission;
  };

  const paginatioObject = computed(() => {
    return store.getters.getSplitPaymentPaginationObject;
  });

  const currentPage = ref(1);

  const loading = computed(() => {
    return store.getters.getSplitPaymentAiLoading;
  });

  const installmentPaymentStatusFilter = [
      {
          label: "All Installments",
          value: "3"
        },
        {
          label: "Paid Installments",
          value: "1"
        },
        {
          label: "Pending Installments",
          value: "2"
        }
    ];


  return {
    roles,
    customFunction,
    loading,
    filterDate,
    paginatioObject,
    currentPage,
    selectedPaymentType,
    selectedInstallmentPaymentStatus,
    permissions,
    loadSplitPaymentInformation,
    getPaymentTypeFor,
    getInstallmentPaymentStatus,
    search,
    searchSplitPayment,
    onPerPageChange,
    getPaginationUrl,
    getSplitPaymentListFor,
    bodyParameters,
    getSplitPaymentListPeriod,
    resetBodyParam,
    responseResult,
    userPermissions,
    splitPaymentList,
    currentInvoiceId,
    paymentTypes,
    getSplitPaymentList,
    changeSplitPaymentStatus,
    openSplitPaymentModal,
    closeSplitPaymentModal,
    submitSplitPaymentModal,
    isError,
    splitPaymentModalRef,
    installmentPaymentStatusFilter
  };
}
});
